import React from 'react';

import ContentBreadcrumb from '../components/content/ContentBreadcrumb/ContentBreadcrumb';
import ContentHeader from '../components/content/ContentHeader/ContentHeader';
import SearchResults from '../components/content/SearchResults/SearchResults';
import Layout from '../components/layout/layout';
import Seo from '../components/layout/seo';

const SearchPage = ({ pageContext }) => {
    let {
        breadcrumb: { crumbs },
    } = pageContext;

    return (
        <Layout>
            <Seo title="Search results" />
            <ContentBreadcrumb crumbs={crumbs} label="Search results" />
            <ContentHeader title="Search results" />
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <SearchResults />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default SearchPage;
