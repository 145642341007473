import { useLocation } from '@reach/router';
import _has from 'lodash/has';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';

import FieldLink from '../../field/FieldLink/FieldLink';
import './search-results.scss';

/**
 * Component for rendering a list of members with a filter.
 */
const SearchResults = () => {
    const [searchResults, setSearchResults] = useState([]);
    const location = useLocation();

    const goToPage = (url) => {
        fetch(url)
            .then((response) => response.json()) // parse JSON from request
            .then((resultData) => {
                setSearchResults(resultData);
            }); // set data for the number of stars
    };

    const goToNextPage = () => {
        if (searchResults.links.next) {
            goToPage(searchResults.links.next.href);
        }
    };

    const goToPrevPage = () => {
        if (searchResults.links.prev) {
            goToPage(searchResults.links.prev.href);
        }
    };

    const goToFirstPage = () => {
        if (searchResults.links.first) {
            goToPage(searchResults.links.first.href);
        }
    };

    const goToLastPage = () => {
        if (searchResults.links.last) {
            goToPage(searchResults.links.last.href);
        }
    };

    // Client-side code, only executed in the browser. Initial state when page
    // loads or when the query parameters change.
    useEffect(() => {
        const rootUrl = process.env.GATSBY_API_URL + `/jsonapi/index/content?page[limit]=10`;
        const filters = [
            {
                filter: `fulltext`,
                label: `Name, city or certificate`,
                name: `keywords`,
            },
        ];
        // Fetch the currently active filters.
        // Set default form values based on the query parameters in the URL.
        const values = queryString.parse(location.search);
        // Update the results.
        let filterValues = {};
        filters.forEach((filter) => {
            if (values[filter.name]) {
                filterValues[`filter[${filter.filter}]`] = values[filter.name];
            }
        });
        goToPage(`${rootUrl}&${queryString.stringify(filterValues)}`);
        // Make sure useEffect is called when query params are changed.
    }, [location]);

    return (
        <div className="search-results">
            {searchResults.meta !== undefined && (
                <p className="search-results--label">
                    Your search yielded {searchResults.meta.count} results:
                </p>
            )}
            {searchResults.data !== undefined &&
                searchResults.data.length > 0 &&
                searchResults.data.map((result, i) => {
                    const resultKey = `result-${i}`
                    return (
                        <div
                            className="search-result"
                            key={resultKey}>
                            {_has(result, 'attributes.path.alias') && (
                                <FieldLink
                                    link={{ uri: result.attributes.path.alias }}
                                    linkClasses={["search-result--link"]}>
                                    <h3>{result.attributes.title}</h3>
                                    {_has(
                                        result,
                                        'attributes.field_introduction.processed',
                                    ) && (
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: result.attributes
                                                    .field_introduction
                                                    .processed,
                                            }}
                                        />
                                    )}
                                    {_has(
                                        result,
                                        'attributes.field_summary.processed',
                                    ) && (
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: result.attributes
                                                    .field_summary.processed,
                                            }}
                                        />
                                    )}
                                    {_has(
                                        result,
                                        'attributes.field_member_intro',
                                    ) && (
                                        <p>
                                            {
                                                result.attributes
                                                    .field_member_intro
                                            }
                                        </p>
                                    )}
                                    <span className="search-result--icon">
                                        <i className="bi bi-arrow-right-short"></i>
                                    </span>
                                </FieldLink>
                            )}
                        </div>
                    );
                })}

            <div className="pager">
                {searchResults.links && searchResults.links.first !== undefined && (
                    <button onClick={() => goToFirstPage()}>
                        <i className="bi bi-chevron-double-left"></i> First
                    </button>
                )}
                {searchResults.links && searchResults.links.prev !== undefined && (
                    <button onClick={() => goToPrevPage()}>
                        <i className="bi bi-chevron-left"></i> Prev
                    </button>
                )}
                {searchResults.links && searchResults.links.next !== undefined && (
                    <button onClick={() => goToNextPage()}>
                        Next <i className="bi bi-chevron-right"></i>
                    </button>
                )}
                {searchResults.links && searchResults.links.last !== undefined && (
                    <button onClick={() => goToLastPage()}>
                        Last <i className="bi bi-chevron-double-right"></i>
                    </button>
                )}
            </div>
        </div>
    );
};

export default SearchResults;
